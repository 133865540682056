<template>
  <div class="list">
    <page-content :contentTableConfig="contentTableConfig2" :isButtonStyle="true" :dataCount="memberInfoCount"
      :dataList='dataList'>
      <template #items="scope">
        <template v-if="scope.row.items!=null&&scope.row.items.length>0">
          {{scope.row.items[0]['itemName']}}
        </template>
        <template v-else>
          -
        </template>
      </template>
      <template #yejiQty="scope">
        {{(scope.row.yejiQty/100).toFixed(2)}}
      </template>

      <template #handler="scope">
        <div>
          <el-button @click="handleCount(scope.row)" size="small" plain>计算提成</el-button>
        </div>
      </template>

    </page-content>
  </div>
</template>

<script lang="js">
import { defineComponent, reactive, ref } from 'vue'
import { contentTableConfig2 } from '../../config'
import PageContent from '@/components/page-content2/src/page-content.vue'
import { testYeji, countTichang } from '@/service/main/base'
import { ElMessage } from 'element-plus'

export default defineComponent({
  props: {

  },
  components: {
    PageContent
  },
  setup() {

    const dataList = ref([])
    const handleCount = async item => {
      let { yejiType, orderNo, empId, shopId } = item
      let obj = {
        orderNo,
        empId,
        shopId,
        bindKind: yejiType,
      }
      const res = await countTichang(obj)
      if (res.code == 0) {
        ElMessage({
          message: '计算提成成功',
          type: 'success',
        })
      }
    }


    const initPage = async () => {
      const res = await testYeji()
      dataList.value = res.data.list
    }
    initPage()

    return {
      dataList,
      contentTableConfig2,
      handleCount
    }

  }
})
</script>

<style scoped lang="less">
.list {}
</style>




